import { getModePicto } from 'pnp_core_bundle/plugnplay/customize_client';
import { getLinePicto } from 'pnp_core_bundle/plugnplay/customize_client';
import { manageGeoMarkers } from 'pnp_core_bundle/modules/map-popup';
import { clearMarker, clearMap } from 'pnp_core_bundle/modules/map';
import { initAutocompleteInputs } from "../models/autocomplete";
import { params } from 'pnp_core_bundle/structure/constants';
import { showHistoricList, addHistoricSearchToCookie, filterHistoricList } from 'pnp_core_bundle'
import {BookmarkClientFactory} from "bookmark_bundle/client/bookmark-client-factory";
import {executeIfMapExist} from "pnp_core_bundle/modules/map/function";

export const AutocompleteView = Backbone.View.extend({

    collection: Kisio.Collections.Places,

    parameters: {
        resetCache:false,
        dataCachePlaces: new Backbone.Collection,
        dataCachePtObjects: new Backbone.Collection,
        configCache: new Backbone.Collection,
        routeId: 'r_places',
        fieldHiddenSelector : '.autocompletable-hidden',
        flagClassName : 'ctp-autocomplete',
        autocomplateClassName : 'autocomplete',
        activeClearButton: true,
        defaultVal: null,
        scheduleAutocompleteInput: 'schedule_stop_area_lines_autocomplete',
        scheduleAutocompleteClearId: '#schedule-stop-area-lines-autocomplete-clear',
        inputJourneyFromId: 'search_from_autocomplete',
        inputJourneyToId: 'search_to_autocomplete',
        journeySearchReverse: '#journey-search-reverse',
        coordinatesFrom: '',
        coordinatesTo: '',
        formNewSearchId: '#journey-form-new-search',
        classContent: '.kisio-result',
        currentRequest: null,
        dataType: {
            'search_from_autocomplete': 'from',
            'search_to_autocomplete': 'to'
        }
    },

    initialize: function(options) {
        this.parameters = {...this.parameters, ...options};
        const geo = new Promise((resolve, reject) =>
            navigator.geolocation.getCurrentPosition(resolve, reject)
        );     
        geo.then(
            e => {
                this.coords = {lat: e.coords.latitude, lon: e.coords.longitude}
            }
        )
        if (!this.hasAutocompleteFlag()) {
            this.addAutocompleteFlag();
            this.$el.data('view', this);
            this.initWidget();
            this.parameters.defaultVal = JKisio.trim(this.$el.val());
        }

        document.addEventListener("geoloGranted", (e) => {
            this.parameters.dataCachePlaces = new Backbone.Collection;
            this.coords = {lat: e.detail.coords.latitude, lon: e.detail.coords.longitude};
        })

        initAutocompleteInputs();
    },

    /**
     * Permet de savoir si le champs est geré par le script
     * @return {boolean}
     */
    hasAutocompleteFlag: function() {
        return this.$el.hasClass(this.parameters.flagClassName);
    },

    /**
     * Ajoute une classe pour identifier que le champs est geré par le script
     */
    addAutocompleteFlag: function() {
        this.$el.addClass(this.parameters.flagClassName);
    },

    /**
     * Recupere une configuration depuis la cache
     * @return {object|false}
     */
    loadConfiguration: function() {
        var config = this.parameters.configCache.findWhere({group: this.getGroup()});
        if (config !== void 0) {
            this.parameters = {
                ...this.parameters,
                ...config.attributes.autocomplete
            };
            return true;
        } else {
            return false;
        }
    },

    /**
     * Recupere le groupe du champs autocomplete
     * @return {string|undefined}
     */
    getGroup: function() {
        return this.$el.data('group');
    },

    /**
     * Instancie le widget autocomplete de jQuery UI
     * @param {object} config Configuration du widget
     */
    initWidget: function() {
        var self = this;
        var inputType = this.$el.attr('id');
        var minLengthOption = this.getMinLength(inputType);
        this.$el.autocomplete({
            source: JKisio.proxy(this.getSource, this),
            minLength: minLengthOption,
            delay: 100,
            select: function(event, ui) {
                self.setData(
                    ui.item.value,
                    ui.item.id,
                    ui.item.entryPoint.coord,
                    inputType,
                    ui.item.type,
                    ui.item.entryPoint.lines
                );
                self.parameters.defaultVal = ui.item.value;
                document.body.dispatchEvent(new CustomEvent(`#${self.$el.attr('id')}-item-selected`, {
                    detail: {
                        id: ui.item.id,
                        network_id: ui.item?.line?.network?.id
                    }
                }));
            },
            open: function (event, ui) {
                self.setOpenReverse();
                self.setLoading(false, self.$el.attr('id'));
                self.setAriaLabel(self.$el.attr('id'));
                self.autocompleteScroll(self.$el.attr('id'));
                self.moveLocationIcon(inputType);
                document.dispatchEvent(new CustomEvent('autocomplete_open', {
                    detail: {
                        event: event,
                        ui: ui,
                        id: self.$el.attr('id') || null
                    }
                }));
            },
            change: function (event, ui) {
                self.setAriaLabel(self.$el.attr('id'));
            },
            search: function() {
                self.setLoading(true, self.$el.attr('id'));
            },
            close: function (event, ui) {
                document.dispatchEvent(new CustomEvent('autocomplete_close', {
                    detail: {
                        event: event,
                        ui: ui,
                        id: self.$el.attr('id')
                    }
                }));
                self.setCloseReverse();
                JKisio('.locate-position').filter('[data-type='+ self.parameters.dataType[self.$el.attr('id')] +']').show();
            }
        }).focus(function() {
        }).data('ui-autocomplete')._renderItem = JKisio.proxy(this.renderItem, this);
        var widget = this.$el.autocomplete('widget');
        widget.addClass(inputType);
        widget.attr('aria-live', 'polite');
        var listItemId = widget.attr('id');
        this.$el.attr('data-target-list', listItemId);
        this.$el.attr('aria-owns', listItemId);
        var clearIcon = JKisio('#' + this.$el.attr('id').replace(/_/gi, '-') + '-clear');
        if (!this.$el.val()) {
            clearIcon.hide();
        }
        this.addClearListener(clearIcon, this.$el.attr('id'));
    },

    /**
     * Function to set the aria-Label attribute of the Autocomplete List
     * @param widget
     * @param inputID
     */
    setAriaLabel: function(inputID) {
        var ul = JKisio('.ui-autocomplete' + '.' + inputID);
        var num = ul.children('.ui-menu-item').length;
        var keyTrad = 'places.autocomplete.ariaLabelOptional';
        if (inputID.indexOf('schedule') > -1 || inputID.indexOf('proximity') > -1) {
            keyTrad = 'places.autocomplete.ariaLabelMandatory';
        }
        ul.attr('aria-label', Translator.trans(keyTrad, {number: num}));
    },

    /**
     * Permet de definir les valeurs pour le champs texte et le champs caché
     * @param {string} label Valeur du champs texte
     * @param {string} uri Valeur du champs caché
     * @param {string} coord Valeur de data-coord du champs caché
     * @param {string} type de l’item sélectionné
     */
    setData: function(label, uri, coord, inputType, itemType, lines) {
        this.$el.removeClass('error');
        this.$el.val(label||'');
        var autocompleteHidden = this.$el.closest('div.autocomplete').find(this.parameters.fieldHiddenSelector);

        if (inputType == 'proximity_search_uri_autocomplete') {
            autocompleteHidden = JKisio('#proximity_search_uri_autocomplete-hidden');
        }
        autocompleteHidden.val(uri||'');
        //enable historic search if necessary
        if (Kisio.enable_historic_search !== undefined && Kisio.enable_historic_search && Kisio.user_consent
            && itemType !== 'historic' && this.isEnabledHistoricByInputType()){
            addHistoricSearchToCookie(label, uri, coord, itemType, lines);
        }

        //mise en place des coordonées géographique
        autocompleteHidden.data('coord', coord||'');
        autocompleteHidden.data('title', label||'');
        switch (inputType) {
            case 'schedule_stop_area_lines_autocomplete':
                if (typeof coord !== 'undefined') {
                    var coords = coord.lat + ';' + coord.lon;
                    JKisio('#schedule_stop_area_lines_autocomplete').attr('data-coord', coords);
                }
                // Mise à jour de la liste des lignes et arrêts
                autocompleteHidden.trigger('displaySearchScheduleResults', [uri, label]);
                document.body.dispatchEvent(new CustomEvent('schedule_autocomplete_selected_item', {
                    detail: {
                        value: uri,
                        label: label
                    }
                }));
                break;
            default:
                if (typeof(coord) === 'object') {
                    //Envoi de l'évènement AutoCompleteCoord qui permettra de mettre à jour une map
                    autocompleteHidden.trigger('autocompletecoord');
                }
                break;
        }
        var fieldType = this.$el.attr('id').replace(/_/gi, ' ').split(' ')[1];
        // add marker only when select item from journey section
        if (inputType &&
            inputType !== this.parameters.scheduleAutocompleteInput &&
            this.$el.attr('show-marker') !== "false"
        ) {
            manageGeoMarkers(fieldType, label, coord, 'autocomplete');
        }

        initAutocompleteInputs();
    },

    getMinLength: function(id) {
        if (this.$el.attr('length')) {
            return this.$el.attr('length');
        }

        if (id === this.parameters.scheduleAutocompleteInput) {
            return Kisio.autocomplete_config.schedule.min_lenght;
        }

        return Kisio.autocomplete_config.journey.min_lenght;
    },

    /**
     * Open/Close icon reverse when autocomplete showing
     */
    setOpenReverse: function(){
        if (this.$el.is('#' + this.parameters.inputJourneyFromId))
            JKisio(this.parameters.journeySearchReverse).hide();
        this.$el.parent().addClass('autocomplete-display-radius');
    },
    setCloseReverse: function(){
        if (this.$el.is('#' + this.parameters.inputJourneyFromId))
            JKisio(this.parameters.journeySearchReverse).show();
        this.$el.parent().removeClass('autocomplete-display-radius');
    },
    moveLocationIcon: function(inputType)
    {
        var ul = JKisio('.ui-autocomplete' + '.'+ inputType);
        if (inputType === this.parameters.inputJourneyFromId || inputType === this.parameters.inputJourneyToId) {
            
            ul.prepend(
                `<div class="locate-position location-autocomplete" id="location-icon-from" data-type="${this.parameters.dataType[inputType]}">
                <em role="button" class="ikisio ikisio-locate"></em>
                <span id="location-text">${Translator.trans('places.autocomplete.position')}</span></div>`
            );
        }
    },

    /**
     * Ajoute/supprime la classe de chargement des données
     * @param {boolean} loading En cour de chargement ?
     */
    setLoading: function(loading, id) {
        if (loading) {
            this.$el.addClass('spinner');
        } else {
            this.$el.removeClass('spinner');
        }
        var widthMode = JKisio('#' + id).data('width_mode') || 'width';

        var positionInput = JKisio('#' + id).parent().offset();
        var widthInput = widthMode === "width" ? JKisio('#' + id).parent().width() : JKisio('#' + id).parent().outerWidth();

        JKisio('.ui-autocomplete').css({
            left: positionInput.left + 'px',
            width: widthInput
        });
    },

    /**
     * add scroll on autocomplete widget when is out of body
     * @param id
     */
    autocompleteScroll: function (id) {
        var ul = JKisio('.ui-autocomplete' + '.' + id);
        var heightBody = JKisio('body').outerHeight(true);
        var heightUl = 15;
        ul.children().each(function () {
            heightUl = heightUl + JKisio(this).height();
        });
        var positionTop = ul.position().top;
        var positionBottom = heightUl + positionTop;
        var diff = heightBody - positionBottom;

        if (diff < 0) {
            heightUl = heightUl + diff - (2*heightBody/100);
        }
        ul.css('height', heightUl);
    },
    /**
     * Recupere les valeurs de l'autocomplete depuis le cache ou le serveur
     * @param {object} request Saisie utilisateur
     * @param {function} response
     */
    getSource: function(request, response) {
        var inputType = this.$el.attr('id');
        var origin = this.$el.attr('origin') ?? '';

        if (inputType == 'search_from_autocomplete' || inputType == 'search_to_autocomplete') {
            origin = 'journey';
        } else if (inputType == 'schedule_stop_area_lines_autocomplete'){
            origin = 'schedule';
        } else if (inputType == 'proximity_search_uri_autocomplete') {
            origin = 'proximity';
        } else if (inputType == 'autocomplete-bookmark') {
            origin = 'bookmark';
        }

        var places, ptObjects;
        var dataRequest = {q: JKisio.trim(request.term), origin: origin};
        var placesHistoric = [];
        if (Kisio.enable_historic_search && Kisio.user_consent && this.isEnabledHistoricByInputType()) {
            placesHistoric = this.addHistoricToAutocompletePlaces(placesHistoric);
        }
        if (this.parameters.type) {
            dataRequest.type = this.parameters.type;
        }
        if (typeof this.getGroup() !== 'undefined' && this.getGroup() !== 'default') {
            dataRequest.group = this.getGroup();
        }
        if(!this.parameters.resetCache && window.Kisio.autocomplete_config.geolocation === 'granted'){
            this.parameters.dataCachePlaces = new Backbone.Collection;
            this.parameters.resetCache = true;
        }

        if (this.getDataCache(dataRequest, placesHistoric)) {
            response(this.getDataCache(dataRequest, placesHistoric));
        } else {
            const params = {
                type_product: (Kisio.type_product !== undefined) ? Kisio.type_product : ''
            };
            if (JKisio('#search_from_autocomplete-hidden').val() !== undefined && JKisio('#search_from_autocomplete-hidden').val() != ''
            && JKisio('#search_from_autocomplete-hidden').val().match('^[-+]?([1-8]?\\d(\\.\\d+)?|90(\\.0+)?);\\s*[-+]?(180(\\.0+)?|((1[0-7]\\d)|([1-9]?\\d))(\\.\\d+)?)$') ) {
                params.coords = JKisio('#search_from_autocomplete-hidden').val();
            } else if(window.Kisio.autocomplete_config.geolocation === 'granted'){
                executeIfMapExist(() => {
                    window.leaflet.map.on('locationfound', function(e){
                        params.coords = e.latlng.lng+';'+e.latlng.lat;
                    });
                });
            }

            if (this.coords) {
                params.coords = this.coords.lon+';'+this.coords.lat;
            }

            const self = this;
            this.parameters.currentRequest = JKisio.ajax({
                url: Routing.generate(this.parameters.routeId, params),
                data: dataRequest,
                dataType: "json",
                beforeSend: function () {
                    if (self.parameters.currentRequest !== null) {
                        self.parameters.currentRequest.abort();
                    }
                },
                success: JKisio.proxy(function(result) {
                    this.filterBookmarks(dataRequest);
                    places = this.processPlaces(result);
                    ptObjects = this.processPtObjects(result);
                    if (places) {
                        this.addDataCachePlaces(dataRequest, places);
                        var placesDef = placesHistoric.concat(places);
                        response(placesDef);
                    }
                    if (ptObjects) {
                        this.addDataCachePtObjects(dataRequest, ptObjects);
                        response(ptObjects);
                    }
                }, this)
            });
        }

    },

    filterBookmarks: function(dataRequest) {

        this.findAddressBookmarks = [];

        if(dataRequest.origin !== "journey") {
            return;
        }
        
        const regex = new RegExp(dataRequest.q.replace(/[.()]/g, '\\$&'), "i");
        let client = new BookmarkClientFactory().createClient();

        let addressBookmarks = client.getSync().filter((bookmark) => {
            return bookmark.type.includes("address")
        });

        this.findAddressBookmarks =  addressBookmarks.filter(bookmark => {
            return regex.test(bookmark.data.name) || regex.test(bookmark.data.address);
        });
    },

    /**
     * Function to add Historic entries already filtered to Autocomplete list
     * @param dataRequest
     * @param placesHistoric
     * @returns {*}
     */
    addHistoricToAutocompletePlaces: function(placesHistoric) {
        var historicList = JKisio('.historic-list li:visible');
        if (historicList !== undefined && historicList !== null && historicList.length > 0) {
            for (var i=0; i<historicList.length; i++){
                var input = JKisio(historicList[i]).text();
                // Clean &nbsp; characters before using it in the autocomplete format
                var value = input.replaceAll(/\s/g, ' ');
                var id = JKisio(historicList[i]).attr('id');
                var coord = {
                    lat: JKisio(historicList[i]).data('coord').split(';')[0],
                    lon: JKisio(historicList[i]).data('coord').split(';')[1]
                };
                // Creation of a place, with information built depending on the format used for the autocomplete
                var place = {
                    id: id,
                    value: value,
                    icon_mode_type: 'times',
                    type: 'historic',
                    entryPoint: {
                        coord: coord
                    }
                };
                // Here to create the label for the section type
                if (i === 0) {
                    place.firstElement = 'true';
                }
                placesHistoric.push(place);
            }
        }
        return placesHistoric;
    },

    getDataCache: function (dataRequest, placesHistoric) {
        if (typeof dataRequest.group !== 'undefined' && dataRequest.group === 'stop_area_and_lines' && this.hasDataCachePtObjects(dataRequest)) {
            var ptObjects = this.getDataCachePtObjects(dataRequest);
            return ptObjects;
        } else if (this.hasDataCachePlaces(dataRequest)) {
            var placesCache = this.getDataCachePlaces(dataRequest);
            var places = placesHistoric.concat(placesCache);
            return places;
        }

        return false;
    },

    /**
     * Filtre les elements a afficher
     * @param {array} data
     * @return {array}
     */
    processPlaces: function(data) {
        if (data !== null) {
            if (data.places !== void 0) {
                data.places = this.renderItemByType(data.places);
                return JKisio.map(data.places, function(item) {
                    if (item.name !== void 0 && item.name !== "" && item.id !== void 0 && item.id !== "") {
                        var place = {
                            id: item.id,
                            value: item.name,
                            distance:item.distance
                        };
                        if(item.embedded_type == 'poi' && item.poi != undefined ){
                            place.addresse = item.poi.address.label;
                        }
                        if (item.position !== void 0){
                            place.position = item.position;
                        }
                        if (item.firstElement !== void 0){
                            place.firstElement = item.firstElement;
                        }
                        if (item.icon_mode_type !== void 0){
                            place.icon_mode_type = item.icon_mode_type;
                        }
                        if (item.embedded_type !== void 0) {
                            place.type = item.embedded_type;
                            place.entryPoint = item[item.embedded_type];
                        } else {
                            place.type = item['id'].split(':')[0];
                            place.entryPoint = '';
                        }
                        return place;
                    }
                });
            }
        }
    },

    processPtObjects: function(data) {
        if (data.pt_objects !== void 0) {
            data.pt_objects = this.renderItemByType(data.pt_objects);
            return JKisio.map(data.pt_objects, function(item) {
                if (item.name !== void 0 && item.name !== "" && item.id !== void 0 && item.id !== "") {
                    var val = item.name;
                    var place = {
                        id: item.id,
                        value: item.name
                    };
                    if (item.position !== void 0){
                        place.position = item.position;
                    }
                    if (item.firstElement !== void 0){
                        place.firstElement = item.firstElement;
                    }
                    if (item.icon_mode_type !== void 0){
                        place.icon_mode_type = item.icon_mode_type;
                    }
                    if (item.embedded_type !== void 0) {
                        place.type = item.embedded_type;
                        place.entryPoint = item[item.embedded_type];
                        if (item.embedded_type === 'line' && item.line !== void 0) {
                            place.value = item.line.name;
                            place.line = item.line;
                        }
                    } else {
                        place.type = item['id'].split(':')[0];
                        place.entryPoint = '';
                    }
                    return place;
                }
            });
        }
    },

    pushBookmarksToList: function (itemList)  {
        this.findAddressBookmarks.forEach((bookmark) => {
            let item = {
                id: bookmark.id,
                name: bookmark.data.name,
                quality: 0,
                distance: 0,
                embedded_type: "address_bookmark",
                icon_mode_type: bookmark.data.icon.replace("ikisio-", ""),
                coord: {
                    lat: bookmark.data.latitude,
                    lon: bookmark.data.longitude,
                },
                address_bookmark: {
                    id: bookmark.id,
                    coord: {
                        lat: bookmark.data.latitude,
                        lon: bookmark.data.longitude,
                    },
                    house_number: bookmark.data?.houseNumber,
                    label: bookmark.data.address,
                    name: bookmark.data.address.replace(/\s*\([^)]*\)$/, ""),
                    administrative_region: {}
                },
            };
            itemList[0].bookmark.push(item);
        });
    },

    renderItemByType: function(items) {
        var self = this;
        // @TODO make places list in commun_parameters
        var itemList= [{ bookmark: [], line: [], stopArea: [], poi: [], address: [], administrative_region: [], defaultType: [] }];
        this.pushBookmarksToList(itemList);
        var datafiltred = [];
        if (typeof items != 'undefined'){
            for (var i=0, l=items.length; i < l; i++) {
                switch (items[i].embedded_type) {
                    case 'line':
                        itemList[0].line.push(items[i]);
                        break;
                    case 'stop_point':
                    case 'stop_area':
                        items[i].icon_mode_type = 'stop_area';
                        itemList[0].stopArea.push(items[i]);
                        break;
                    case 'address':
                        items[i].icon_mode_type = 'address';
                        itemList[0].address.push(items[i]);
                        break;
                    case 'administrative_region':
                        items[i].icon_mode_type = 'address';
                        itemList[0].administrative_region.push(items[i]);
                        break;
                    case 'poi':
                        items[i].icon_mode_type = 'poi';
                        itemList[0].poi.push(items[i]);
                        break;
                    case 'address_bookmark':
                        break;
                    default:
                        itemList[0].defaultType.push(items[i])
                }
            }

            for (const itemGroup in itemList[0]) {
                if (itemList[0][itemGroup] !== 'undefined' && itemList[0][itemGroup].length > 0){
                    for (const key in itemList[0][itemGroup]) {
                        itemList[0][itemGroup][key].position = self.getSectionPos(parseInt(key), itemList[0][itemGroup].length);
                    }
                    itemList[0][itemGroup][0].firstElement = 'true';
                    datafiltred = datafiltred.concat(itemList[0][itemGroup]);
                }
            }
            return datafiltred;
        }
    },

    // desactive histories for all the inputType inside the array
    isEnabledHistoricByInputType: function () {
        return !this.$el.attr('id').includes("bookmark");
    },


    /**
     * Function to know the position of the section
     * @param {Number} index
     * @param {Number} lenght
     * @returns {String}
     */
    getSectionPos: function(index, lenght)
    {
        var sectionPos = {};
        if (index === 0){
            sectionPos.libelle = 'first';
            if(index === lenght-1) {
                sectionPos.libelle = 'last';
            }
        } else if (index === lenght-1) {
            sectionPos.libelle = 'last';
        } else {
            sectionPos.libelle = 'other';
        }
        sectionPos.index = index;
        return sectionPos;
    },

    /**
     * Mise en page d'un element
     * @param {domelement} ul Noeud parent de l'element à afficher
     * @param {object} item Element à mettre en page
     * @return {domelement} Noeud parent dans lequel on à ajouté l'element
     */
    renderItem: function(ul, item) {
        var renderItem = new AutocompleteItems({
            ul: ul,
            item: item,
            self: this
        });

        return renderItem.setItem();
    },

    /**
     * Ajoute un resultat au cache
     * @param {object} ref requete
     * @param {object} data description
     */
    addDataCachePlaces: function(ref, data) {
        var cache = new Backbone.Model({
            term: ref.q,
            group: ref.type,
            result: data
        });
        this.parameters.dataCachePlaces.add([cache]);
    },

    /**
     * Ajoute un resultat au cache
     * @param {object} ref requete
     * @param {object} data description
     */
    addDataCachePtObjects: function(ref, data) {
        var cache = new Backbone.Model({
            term: ref.q,
            group: ref.type,
            result: data
        });
        this.parameters.dataCachePtObjects.add([cache]);
    },

    /**
     * Permet de savoir si le cache a une valeur pour la reference donnée
     * @param {object} ref requete
     * @return {boolean}
     */
    hasDataCachePlaces: function(ref) {
        var cache = this.parameters.dataCachePlaces.findWhere({
            term: ref.q,
            group: ref.type
        });
        return cache !== void 0;
    },

    /**
     * Permet de savoir si le cache a une valeur pour la reference donnée
     * @param {object} ref requete
     * @return {boolean}
     */
    hasDataCachePtObjects: function(ref) {
        var cache = this.parameters.dataCachePtObjects.findWhere({
            term: ref.q,
            group: ref.type
        });
        return cache !== void 0;
    },

    /**
     * Recupere la valeur en cache
     * @param {object} ref requete
     * @return {object}
     */
    getDataCachePlaces: function(ref) {
        var model = this.parameters.dataCachePlaces.findWhere({
            term: ref.q,
            group: ref.type
        });
        return model.get('result');
    },

    /**
     * Recupere la valeur en cache
     * @param {object} ref requete
     * @return {object}
     */
    getDataCachePtObjects: function(ref) {
        var model = this.parameters.dataCachePtObjects.findWhere({
            term: ref.q,
            group: ref.type
        });
        return model.get('result');
    },

    /**
     * Fonction permettant d'ajouter le listener
     * @param {string} clearField Span du bouton (x) pour la suppression
     * @param {string} itemId Id du champ de saisie
     */
    addClearListener: function(clearField, itemId)
    {
        JKisio(clearField).on('click',
            JKisio.proxy(function() {
                JKisio('#' + itemId).val('');
                JKisio('#' + itemId + '-hidden').val('');
                clearField.hide();

                if ([this.parameters.inputJourneyFromId, this.parameters.inputJourneyToId].includes(itemId)) {
                    JKisio('.journey-line-container').hide();
                    JKisio('.via-container').hide();

                    if (JKisio('#error_popup').length > 0) {
                        JKisio('#error_popup').remove();
                    }

                    clearMarker('#' + itemId);
                    if (itemId === this.parameters.inputJourneyFromId) {
                        window.autocomplete.from = '';
                    } else if (itemId === this.parameters.inputJourneyToId) {
                        window.autocomplete.to = '';
                    }

                    /*
                     * The values below were obtained from params, but are override in other js files
                     * So we put these values in hard
                     * Params changed : classContent, formNewSearchId, boardContainerId
                     */
                    if (JKisio('.kisio-result').length > 0) {
                        JKisio('.kisio-result').hide();
                        JKisio('#journey-form-new-search').show();
                        executeIfMapExist(() => {
                            window.leaflet.map.eachLayer(function(layer) {
                                if (typeof layer._path !== 'undefined') {
                                    window.leaflet.map.removeLayer(layer);
                                }
                            });
                        });
                    }
                }

                document.body.dispatchEvent(new CustomEvent('#' + itemId + '-cleared'));
            }, this)
        );
    }
});

export const AutocompleteItems = Backbone.View.extend({

    parameters: {
        showMatcher: true
    },

    initialize: function(options) {
        this.parameters = {
            ...this.parameters,
            ...options
        };
    },
    
    setItem: function (typeSetItem) {
        var type = '';
        var distanceElement = '';
        var lineElement = '';
        var networkSpan = '';
        var lineMode = '';
        var addresseName = '';
        if ('StopArea' === this.parameters.item.type) {
            this.parameters.item.value = this.parameters.item.value.charAt(0).toUpperCase() + this.parameters.item.value.slice(1).toLowerCase();
        }
        var zebra = (this.parameters.ul.children().length % 2 === 0)? 'ctp-odd' : 'ctp-even';
        var title = Translator.trans('places.autocomplete.title.'+ this.parameters.item.type);
        title = title.replace('%value%', this.parameters.item.value).replace('"', "'");
        if (this.parameters.item.firstElement !== void 0 && this.parameters.item.type !== 'historic'){
            var groupName = Translator.trans('places.autocomplete.title.group_'+ this.parameters.item.type);
            JKisio(this.parameters.ul).append('<label>' + groupName.toLowerCase() + '</label>');
        }
        var iconModeType = this.parameters.item.icon_mode_type;
        var element = '<div class="title"><div><small class="type type-'
            + this.parameters.item.type.toLowerCase() + '"></small>' + '</div></div>';
        if (this.parameters.showMatcher) {
            var keywords = JKisio.trim(this.parameters.self.$el.val()).split(' ');
            keywords = JKisio.map(keywords, function(word) {
                return JKisio.ui.autocomplete.escapeRegex(word);
            });
            var matcher = new RegExp("(" + keywords.join('|') + ")", "ig" );

            element = JKisio(element).append('<span aria-hidden="true">' + this.parameters.item.value.replace(matcher, "<strong class='highlight'>$1</strong>") + '</span>');
        } else {
            element = JKisio(element).append('<span aria-hidden="true">' + this.parameters.item.value + '</span>');
        }
        if (this.parameters.item.type === 'line' && this.parameters.item.line) {
            iconModeType = this.parameters.item.line.physical_modes[0].id.split(":")[1].toLowerCase();
            type = getModePicto(iconModeType);
            if (this.parameters.item.line.code) {
                lineElement = getLinePicto(this.parameters.item.line, 'undefined', 'autocomplete-line-code');

            }
            networkSpan = JKisio('<span class="autocomplete-network-line" />');
            var networkName = Translator.trans('places.autocomplete.title.network') + ' : ' + this.parameters.item.line.network.name;
            networkSpan.append('<p>' + networkName.toUpperCase() + '</p>');

            var lineMode = JKisio('<div />')
                .addClass('autocomplete-line-mode')
                .append(type)
                .append(distanceElement)
                .append(lineElement);
            distanceElement= '';
            type = '';
            title = iconModeType + ' ' + title;
        } else {
            type = '<i class="ikisio icon-autocomplete-type ikisio-' + iconModeType + '">';
            if(this.parameters.item.distance != undefined && this.parameters.item.type == 'poi' && window.Kisio?.autocomplete_config?.geolocation == 'granted'){
                var distanceByKm = this.parameters.item.distance / 1000;
                distanceByKm = Math.round(distanceByKm * 10) / 10;
                const distance = distanceByKm > 1?distanceByKm +'Km':this.parameters.item.distance +'m';
                type += '<span class="distance">'+distance +'</span>';
            }
            type += '</i>';
        }

        if (this.parameters.item.addresse) {
            addresseName = JKisio('<div />').addClass('item-address').append('<span>' + this.parameters.item.addresse + '</span>');
        }
        var lineHeader = JKisio('<div />')
            .addClass('item-autocomplete-line-header')
            .append(lineMode)
            .append(networkSpan);
        element = JKisio(element).prepend('<span class="sr-only">' + title + '</span>');
        var coords = '';

        if (typeof this.parameters.item.stop_area_coords !== 'undefined') {
            coords = this.parameters.item.stop_area_coords;
        }else if (typeof this.parameters.item.entryPoint.coord !== 'undefined'){
            coords = this.parameters.item.entryPoint.coord.lat+';'+this.parameters.item.entryPoint.coord.lon;
        }

        let prependTitle = "";
        if(this.parameters.item.type === 'address_bookmark')  {
            let bookmarkName = this.parameters.item.entryPoint.name;
            if(this.parameters.showMatcher) {
                bookmarkName = this.parameters.item.entryPoint.name.replace(matcher, "<strong class='highlight'>$1</strong>");
            }
            prependTitle = JKisio(`<div class='title'>${bookmarkName}</div>`);
        }

        var link = JKisio('<a title="' + title + '"/>')
            .attr('id', this.parameters.item.id)
            .attr('data-content', this.parameters.item.stop_area_label)
            .attr('data-coord', coords)
            .attr('role', 'button')
            .attr('aria-label', title)
            .append(lineHeader)
            .append(element)
            .append(prependTitle)
            .append(addresseName);

        if (typeSetItem === 'schedule_line_list') {
            link.attr('tabindex', 0);
        }
        if (typeof this.parameters.item.line !== 'undefined' && typeof this.parameters.item.line.network.id !== 'undefined') {
            link.attr('data-network', this.parameters.item.line.network.id);
        }

        var $div = JKisio('<div />')
            // .data('item.autocomplete', this.parameters.item)
            .data('ui-autocomplete-item', this.parameters.item)
            .addClass('ui-menu-item ui-autocomplete-item-' + this.parameters.ul.children().length)
            .addClass(zebra)
            .append(type)
            .append(distanceElement)
            .append(link);

        if (this.parameters.item.position) $div.addClass(this.parameters.item.position.libelle);

        if (typeof this.parameters.item.entryPoint.lines !== "undefined" && this.parameters.item.entryPoint.lines.length > 0) {
            const $linesModesContainer = JKisio('<div />').addClass('lines-modes-container');
        
            // Get an array of physical mode IDs
            const physicalModeIds = this.parameters.item.entryPoint.lines
                .filter(line => line.physical_modes && line.physical_modes.length > 0)
                .map(line => line.physical_modes[0].id)
                .filter((id, index, arr) => arr.indexOf(id) === index);
            physicalModeIds.forEach((physicalModeId) => {
                const physicalName = physicalModeId.split(":")[1];
                const type = physicalName.toLowerCase();
                const modeIconContainer = JKisio('<div />').addClass('icon-container')
                const modeIcon = JKisio('<em />').addClass('ikisio icon-autocomplete-mode ikisio-' + type);
                modeIconContainer.append(modeIcon)
                $linesModesContainer.append(modeIconContainer);
        
                const linesHTML = this.parameters.item.entryPoint.lines
                    .filter(line => line.physical_modes && line.physical_modes.length > 0 && line.physical_modes[0].id === physicalModeId)
                    .map((line) => `<div>${getLinePicto(line, 'undefined', 'autocomplete-line-code')}</div>`);
                    $linesModesContainer.append(linesHTML.join(''));
            });
        
            JKisio($div.children().last()).append($linesModesContainer);
        }

        var $li = JKisio('<li />').addClass('ui-menu-item');
        if (this.parameters.item.type !== 'historic') {
            $div.appendTo($li);
        }
        return $li.appendTo(this.parameters.ul);
    },
});

JKisio(document).on('keyup', '.ui-autocomplete-input', function (e) {
    var inputId = JKisio(this).attr('id');
    if (e.keyCode === 38 || e.keyCode === 40) {
        if (JKisio('.ui-autocomplete.'+inputId+':visible')) {
            JKisio('.ui-autocomplete.'+inputId+' .ui-menu-item').removeClass('item-active');
            var link = JKisio('.ui-autocomplete.'+inputId+' .ui-menu-item a[title$="'+(JKisio(this).val())+'"]');
            JKisio(link).parents('li.ui-menu-item').addClass('item-active');
        }
    } else {
        if (Kisio.enable_historic_search && Kisio.user_consent) {
            filterHistoricList(this);
        }
    }

    if (JKisio(this).val() === "") {
        document.body.dispatchEvent(new CustomEvent('#' + inputId + '-cleared'));
    }
});
